import React from 'react';
import { Check2Circle, InfoCircle } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './FeatureItem.css';

const FeatureItem = ({ feature, index }) => {
  // Componente Link para manejar el tooltip
  const Link = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      {children}
    </OverlayTrigger>
  );

  return (
    <li className="feature-item">
      <div className="fi-title">
        {feature.showIcon && <Check2Circle className='icon' style={{ color: feature.isStrikedThrough ? '#0C2A42' : 'green' }} />}
        
        {feature.showIcon && feature.name}
        {!feature.showIcon && <h1 className='titulo-intermedio'>{feature.name}</h1>}
        {feature.hint && (
          <Link title={feature.hint} id={`tooltip-${index}`}>
            <InfoCircle className='info-circle' />
          </Link>
        )}
      </div>
      <div className="fi-subtitle">
        {feature.sub}
      </div>
    </li>
  );
};

const FeaturesList = ({ features }) => {
  return (
    <ul className="features-list">
      {features.map((feature, index) => (
        <FeatureItem key={index} feature={feature} index={index} />
      ))}
    </ul>
  );
};

export default FeaturesList;
