import React from 'react';
import './Footer.css'; // Este archivo contendrá los estilos CSS
import logo from '../../assets/logo-02.png';
import * as Icon from 'react-bootstrap-icons';

function Navbar() {
  return (
    <div className="navbar-container">
      <div className="logo">
        <img src={logo} alt="logo" className='ftr-logo'/>
        <h5 className='txt-copyright'>© 2023 LLC-YA! Todos los derechos reservados.</h5>
        <div className="social">
          <a href='https://www.instagram.com/llc__ya' target='_blank' className='social_link'>
            <Icon.Instagram size={20}/>
          </a>
          <a href='https://www.linkedin.com/company/llc-ya/' target='_blank' className='social_link'>
            <Icon.Linkedin size={20}/>
          </a>
        </div>
      </div>
      <div className="menu">
        <a href="#inicio">Home</a>
        <a href="#features">Servicios</a>
        <a href="#pricing">Pricing</a>
        <a href="#proceso">Procesos</a>
        <a href="#faq">FAQ</a>
        <a href="#contacto">Contacto</a>
      </div>
      <div className="info">
        {/* <div className="legal">
          <a href="/privacidad-datos">Privacidad y Datos</a>
          <a href="/requisitos-legales">Requisitos Legales</a>
        </div> */}
        <div className="contact-info">
          <span className="phone-number">+54 911 2770-8023</span>
          <span className="email-info">info@llc-ya.com</span>
          <span className="location-info">Buenos Aires, Argentina</span>
        </div>
      </div>
    </div>
  );
}

export default Navbar;