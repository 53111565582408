import './App.css';
import TextCarousel from './componets/bloque1_Inicio/Inicio';
import Features from './componets/bloque2_servicios/Features';
import Header from './componets/header/Header';
import Pricing from './componets/bloque3_Pricing/Pricing';
// import Plancard from './componets/bloque3_Pricing/PlanCard';
import Timeline from './componets/bloque4_Timeline/Timeline';
import FAQ from './componets/bloque5_FAQ/FAQ';
import Navbar from './componets/footer/Footer';
import WhatsAppButton from './componets/whatsapp_button/WhatsAppButton';
import ContactForm from './componets/bloque6_contactUs/ContactUs';
import LLC from './componets/bloque7_QueEsUnaLLC/LLC';
function App() {
  
  
  const texts = [
    "Primer Slide",
    "Segundo Slide",
    "Tercer Slide"
  ];
  const backgroundImageUrl = "./assets/back1.png";
  return (
    <div className="App">
      <Header/>
       <TextCarousel textArray={texts} backgroundImage={backgroundImageUrl} />
      <Features/>
      {/* <LLC /> */}
      <Pricing/>
      <ContactForm/>
      <Timeline />
      <WhatsAppButton/>
      <FAQ />
      <Navbar/>
    </div>
  );
}

export default App;
