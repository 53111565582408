import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Pricing.css";
import PlanCard from "./PlanCard";

const plans = [
  {
    title: "Abre tu LLC YA",
    subtitle1: "LLC en Cualquier Estado + Cuenta Bancaria Empresarial",
    subtitle2: "Elegimos estratégicamente el mejor Estado para que reduzcas al máximo tus costos iniciales y de mantenimiento.",
    price: "550",

    features: [
      {
        showIcon: true,
        name: " Formación de LLC  ",
        sub: "Incluye tasas Estatales",
        hint: "Preparación de documentos / Verificación de nombre comercial / Presentación de Artículos de Incorporación al Estado",
      },
      {
        showIcon: true,
        name: " Gestión del EIN  ",
        sub: "",
        hint: "Número de Identificación Fiscal solicitado a la Agencia Impositiva de EE.UU.",
      },
      {
        showIcon: true,
        name: " Contrato interno LLC",
        sub: "",
        hint: "",
      },
      {
        showIcon: true,
        name: " Agente Registrado por 1 año  ",
        sub: "",
        hint: "Incluye dirección para recibir notificaciones fiscales y dirección comercial.",
      },
      {
        showIcon: true,
        name: " Cuenta Bancaria Empresarial Online  ",
        sub: "",
        hint: "Sin deposito inicial / Sin costos de mantenimiento.",
      },
      {
        showIcon: false,
        name: " Además por el mismo precio contas con:",
        sub: "",
        hint: "",
      },
      {
        showIcon: true,
        name: " Soporte Continuo",
        sub: "",
        hint: "",
      },
      {
        showIcon: true,
        name: "Llamada de planificacion con experto impositivo.",
        sub: "",
        hint: "",
      }
    ],
  },
  // {
  //   title: " Abre tu LLC en Cualquier Estado ",
  //   subtitle1: "LLC 2 o más Socios en Cualquier Estado",
  //   subtitle2: "Ideal para Startups de 2 o más founders, Amazon FBA Sellers y muchos más!",
  //   subtitle3: "No pagas reporte anual",
  //   subtitle4: "Apertura Cuenta Bancaria en Mercury",
  //   price: "450",

  //   features: [
  //     {
  //       name: " Elegimos estratégicamente el mejor Estado",
  //       sub: "Para que reduzcas al máximo tus costos iniciales y de mantenimiento.",
  //       hint: "",
  //     },
  //     {
  //       name: " Formación de LLC  ",
  //       sub: "Incluye tasas Estatales",
  //       hint: "Preparación de documentos / Verificación de nombre comercial / Presentación de Artículos de Incorporación al Estado",
  //     },
  //     {
  //       name: " Gestión del EIN  ",
  //       sub: "",
  //       hint: "Número de Identificación Fiscal solicitado a la Agencia Impositiva de EE.UU.",
  //     },
  //     {
  //       name: " Contrato interno LLC",
  //       sub: "",
  //       hint: "",
  //     },
  //     {
  //       name: " Agente Registrado por 1 año  ",
  //       sub: "",
  //       hint: "Incluye dirección para recibir notificaciones fiscales y dirección comercial.",
  //     },
  //     {
  //       name: " Cuenta Bancaria Empresarial Online  ",
  //       sub: "",
  //       hint: "Sin deposito inicial / Sin costos de mantenimiento.",
  //     },
  //     {
  //       name: " Incluye una llamada inicial de planificación impositiva GRATUITA",
  //       sub: "",
  //       hint: "",
  //     },
  //     {
  //       name: " Soporte Continuo GRATUITO",
  //       sub: "",
  //       hint: "",
  //     },
  //   ],
  // },
];

const Pricing = () => {
  return (
    <div className="plans-container" id="pricing">
      <h2 className='plans-title'>OFERTA</h2> 
      <h4 className='plans-subtitle'></h4>
      {plans.map((plan, index) => (
        <PlanCard key={index} plan={plan} />
      ))}
    </div>
  );
};

export default Pricing;
