import React from "react";
import "./Plancard.css"; // Asegúrate de importar tu archivo CSS aquí
import FeatureList from "./feature-item/FeatureItem";
export default function PlanCard({ plan }) {

  const cargarSolicitud = () => {
    var descript = document.getElementById("message");
    descript.textContent = "Hola, quiero agendar una reunión para saber más sobre cómo abrir mi LLC!";
  }

  return (
    <div className="plan-card">
      <div className="pnl-top">
        <div className="pnl_container pnl_left">
          <h3 className="plan-title">{plan.title}</h3>
          <p className="plan-subtitle">{plan.subtitle1}</p>
          <p className="plan-subtitle">{plan.subtitle2}</p>
          <p className="plan-subtitle2">{plan.subtitle3}</p>
          <p className="plan-subtitle2">{plan.subtitle4}</p>
      <div className="features-list">
        <FeatureList features={plan.features} />
      </div>
        </div>
        <div className="pnl_container pnl_right">
          <div className="plan-price">{`US$${plan.price}`}</div>
          <a className='cus-btn-cnt' href="#contacto" onClick={() => { cargarSolicitud() }}>Quiero saber más</a>
        </div>
      </div>
    </div>
  );
};
